import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><path d="m32 2c-16.568 0-30 13.432-30 30s13.432 30 30 30 30-13.432 30-30-13.432-30-30-30m14.035 44.508h-5.65v-19.626c0-.564.008-1.355.02-2.372.014-1.018.02-1.802.02-2.353l-5.498 24.351h-5.893l-5.459-24.351c0 .551.006 1.335.02 2.353.014 1.017.02 1.808.02 2.372v19.626h-5.65v-29.016h8.824l5.281 22.814 5.242-22.814h8.725v29.016z" fill="currentColor"/></svg>
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  //   <title>Logo</title>
  //   <g transform="translate(-8.000000, -2.000000)">
  //     <g transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M62.789,46.228c0.288,2.592,0.792,6.625,3.889,7.633c2.088,0.648,3.96,0.648,4.68,0.648c0.36,0,0.576,0.072,0.576,0.359  c0,0.432-0.647,0.648-1.512,0.648c-1.584,0-9.361-0.072-12.025-0.288c-1.656-0.144-2.016-0.36-2.016-0.72  c0-0.289,0.144-0.433,0.504-0.504c0.36-0.072,0.432-1.297,0.288-2.593l-3.456-37.371H53.5L35.858,51.485  c-1.512,3.168-1.728,3.815-2.376,3.815c-0.504,0-0.864-0.719-2.232-3.312c-1.872-3.528-8.064-15.985-8.424-16.706  c-0.648-1.295-8.713-18.577-9.505-20.521h-0.288l-2.881,33.411c-0.071,1.152-0.071,2.448-0.071,3.673  c0,1.08,0.792,2.016,1.872,2.231c1.224,0.288,2.304,0.36,2.736,0.36c0.287,0,0.575,0.145,0.575,0.359  c0,0.504-0.432,0.648-1.296,0.648c-2.664,0-6.12-0.216-6.696-0.216c-0.648,0-4.104,0.216-6.049,0.216C0.504,55.445,0,55.3,0,54.796  c0-0.215,0.359-0.359,0.72-0.359c0.576,0,1.08,0,2.16-0.217c2.376-0.432,2.593-3.24,2.88-5.976l5.185-46.948  C11.017,0.504,11.305,0,11.736,0c0.433,0,0.721,0.288,1.08,1.081l22.034,45.292l21.17-45.22C56.309,0.504,56.597,0,57.101,0  s0.72,0.576,0.864,1.944L62.789,46.228z"
  //         // d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //       <polygon
  //         id="Shape"
  //         stroke="currentColor"
  //         strokeWidth="5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         points="39 0 0 22 0 67 39 90 78 68 78 23"
  //       />
  //     </g>
  //   </g>
  // </svg>
);

export default IconLogo;
